import { styled } from '@mui/system';
import { Box, Button, TextField } from "@mui/material";

const ContentWrapper = styled(Box)({
    borderRadius: 0,
    color: 'white',
    padding: '50px',
    paddingRight: '0px',
    background: 'rgb(4, 11, 36)',
    width: '100%',
    paddingLeft: '0',
    textAlign: 'center',
    "& .MuiGrid-container": {
        width: "100% !important",
        margin: "0 !important",
        "& .MuiGrid-item": {
            width: "100%",
            paddingLeft: "20px",
            paddingRight: "20px",
        },
    },
    "& .MuiDialog-paper": {
        margin: "0 !important",
    },
});

const CustomTextField = styled(TextField)({
    backgroundColor: "white",
    borderRadius: "5px",
    width: "100%"
});
const FormWrapper = styled(Box)({
    width: '320px'
});


const ButtonColor = styled(Button)({
    backgroundColor: "rgb(3, 180, 198)",
    padding: "14px 50px",
    "&:hover": {
        background: 'rgb(0, 158, 176)',
    },
});
const FormHelperTextNew = styled(Box)({
    width: '223px',
    color: 'red',
});
const ButtonWrapper = styled(Button)({
    padding: '17px 3rem',
    background: '#03b4c6',
    fontWeight: 700,
    color: '#fff',
    letterSpacing: '2px',
    fontSize: '14px',

    whiteSpace: 'nowrap',
    "&:hover": {
        backgroundColor: 'rgb(0, 158, 176)'
    },
    "@media (max-width: 991px)": {
        textAlign: 'inherit',
        height: 'max-content',
        overflowY: 'inherit',
    }
});
export default { ContentWrapper, FormWrapper, CustomTextField, ButtonColor, ButtonWrapper, FormHelperTextNew }