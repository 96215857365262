import React from 'react'
import { useMediaQuery } from '@mui/material';
import { FooterComponent, HeaderComponent } from '../../Components'
import { MainContentWrapper } from './style';
import { ScrollContainer, GellyElement } from 'react-nice-scroll';
import 'react-nice-scroll/dist/styles.css';

const LayoutContainer = (props) => {
    const matches = useMediaQuery('(max-width:991px)');
    const { children } = props;
    return (
        <ScrollContainer damping="0.06">
            <MainContentWrapper>
                <HeaderComponent />
                {children}
                <FooterComponent />
            </MainContentWrapper>
        </ScrollContainer>
    )
}

export default LayoutContainer