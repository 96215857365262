import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: 'Inter',
        },
    },
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderWidth: '5px',
                    borderColor: '#FAFAFA',
                    margin: '1.5rem 0 0.5rem 0',
                }
            }
        }
    }
});

export default theme