import logo from './galileosense.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import { Contact, LandingPage, Product } from './Pages';
import { ThemeProvider } from '@mui/material';
import theme from "./theme"
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from "react-ga4";
import { useEffect } from 'react';
import { MixpanelProvider } from 'react-mixpanel-browser';

function App() {
  const setGA = () => {
    ReactGA.initialize("G-C8PT1PS8GC");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  };

  useEffect(() => {
    setGA();
  })
  return (
    <>
      <MixpanelProvider>
        <GoogleOAuthProvider clientId="307235219846-e0bskohlaq221gbmp5n1kqbko3pvtf7p.apps.googleusercontent.com">
          <ThemeProvider theme={theme}>
            <Router>
              <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route exact path="/Product" element={<Product />} />
                <Route exact path="/Contact" element={<Contact />} />
              </Routes>
            </Router>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </MixpanelProvider>
    </>
  );
}

export default App;
