import React, { useContext, useEffect, useState, lazy } from 'react'
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import Styled from "./style";
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { googleLogout } from '@react-oauth/google';
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, updateProfile, sendPasswordResetEmail } from "firebase/auth";
import { db } from "../../Firebase/firebaseConfig"
import { UserContext } from '../../Pages/LandingPage';
import { useMediaQuery } from '@mui/material';
import {
    Menu as MenuIcon,
    Notifications as NotificationsIcon,
} from '@mui/icons-material';
import { PopupButton } from '@typeform/embed-react'

const LoginComponent = lazy(() => import('../LoginComponent'))

const HeaderComponent = () => {
    const matches = useMediaQuery('(max-width:991px)');
    const learnMoreContext = useContext(UserContext);
    let userInfo = "";
    if (JSON.parse(localStorage?.getItem("userinfo")) !== null) {
        userInfo = JSON.parse(localStorage?.getItem("userinfo"))
    }
    else {
        userInfo = ""
    }
    // object creation
    const auth = getAuth();
    const UsersDataRef = db.collection('user-collection')
    const loginDetail = JSON.parse(localStorage?.getItem("userinfo"));
    // Define states
    const [data, setData] = useState({ SignUp: true, Login: false, Reset: false })
    const [userData, setUserData] = useState(userInfo.name);
    const [open, setOpen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    // useEffect or useMemo ~ Hooks
    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //         console.log('user', user)
    //         if (!user) {
    //             handleLogout()
    //         }
    //     });
    // }, []);
    const responseGoogleSuccess = (response) => {
        localStorage.setItem("token", response.credential);
        const responseUserInfo = jwt_decode(response.credential);
        localStorage.setItem("userinfo", JSON.stringify({
            name: responseUserInfo.name,
        }));
        setUserData(responseUserInfo.name)
        handleClose();
    };

    const responseGoogleFailure = (response) => {
        alert('Login Failed!');
    };

    const handleProductRedirection = () => {
        if (loginDetail) {

        }
        else {
            handleClickOpen()
        }
    }

    const handleLogout = () => {
        auth.signOut()
        googleLogout();
        setAnchorEl(null);
        setUserData("");
        localStorage.clear();
    }
    const handleReset = async (data) => {
        await sendPasswordResetEmail(auth, data.email)
            .then(() => {
                // Password reset email sent!
                // ..
                alert("Succesful!");

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                alert("Fail to sent link" + errorMessage)
            });
    }

    const handleClose = () => {
        setOpen(false);
        learnMoreContext.setOpenForm(false)
        setTimeout(() => {
            setData({ ...data, Login: true, SignUp: false, Reset: false });
        }, 500);
    };

    const handleLoginClick = (e) => {
        setData({ ...data, Login: true, SignUp: false, Reset: false });
    }

    const handleSignClick = (e) => {
        setData({ ...data, Login: false, SignUp: true, Reset: false });
    }

    const handleResetClick = (e) => {
        setData({ ...data, Login: false, SignUp: false, Reset: true });
    }

    const handleSignUp = async (data) => {
        await createUserWithEmailAndPassword(auth, data.email, data.password)
            .then((userCredential) => {
                UsersDataRef.doc().set({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    displayName: (data.firstName + " " + data.lastName)
                }).then(() => {
                    alert("Successfully Registered")
                    handleLoginClick();
                }).catch((error) => {
                    alert("Failed to create record " + error);
                })
            })
            .catch((error) => {
                alert("Signup Failed! " + error);
            });

        await updateProfile(auth.currentUser, {
            displayName: (data.firstName + " " + data.lastName)
        })
    }

    const handleLogin = async (data) => {
        await signInWithEmailAndPassword(auth, data.email, data.password)
            .then((userCredential) => {
                alert("Succesful!")
                const user = userCredential.user;
                setUserData(user.displayName);
                localStorage.setItem("userinfo", JSON.stringify({
                    name: user.displayName,
                }));
                handleClose();
            })
            .catch((error) => {
                alert("Login Failed! ");
            });
    }
    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfileClose = () => {
        setAnchorEl(null);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        if (learnMoreContext.openForm) { handleClickOpen() }
    }, [learnMoreContext.openForm])

    return (
        <>
            {matches ? (
                <>
                    <Styled.HeaderMobileContentWrapper>
                        <Styled.MobileHeaderWrapper>
                            <Button>
                                <Styled.Image src="images/galileosense.webp" alt='Galileosense Logo'></Styled.Image>
                            </Button>
                        </Styled.MobileHeaderWrapper>
                        <Styled.MenuItemsWrapper>
                            <Styled.MenuIcon onClick={() => setOpenDrawer(true)}><MenuIcon />
                            </Styled.MenuIcon>
                        </Styled.MenuItemsWrapper>
                        <Styled.CustomDrawer
                            open={openDrawer}
                            onClose={() => setOpenDrawer(false)}
                        >
                            <Styled.MobileMenuWrapper>
                                <Styled.HeaderMobileMenu>
                                    {userData ? (
                                        <>
                                            <Typography style={{ marginLeft: "25px" }}>
                                                <Typography
                                                    id="profile-button"
                                                    variant="button"
                                                    aria-controls={anchorEl ? 'profile-menu' : undefined}
                                                    aria-expanded={anchorEl ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    style={{ cursor: "pointer", color: "white" }}
                                                    onClick={handleProfileClick}>
                                                    {`Hi ${userData}`}
                                                </Typography>
                                                <Menu
                                                    id="profile-menu"
                                                    anchorEl={anchorEl}
                                                    open={anchorEl ? true : false}
                                                    onClose={handleProfileClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'profile-button',
                                                    }}
                                                >
                                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                                </Menu>
                                            </Typography>
                                        </>
                                    ) : null}
                                    <PopupButton id="E9DCiao0" style={{ backgroundColor: "transparent", border: "0", width: "fit-content", marginLeft: "21px" }} className="my-button">
                                        <Styled.MobileProductLinkUI>
                                            Product
                                        </Styled.MobileProductLinkUI>
                                    </PopupButton>
                                    <Link to="/Contact" style={{ textDecoration: "none" }}>
                                        <Styled.MobileLinkUI>
                                            Contact
                                        </Styled.MobileLinkUI>
                                    </Link>
                                    {userData ? null : (
                                        <>
                                            <LoginComponent
                                                open={open}
                                                setOpen={setOpen}
                                                responseGoogleSuccess={responseGoogleSuccess}
                                                responseGoogleFailure={responseGoogleFailure}
                                                handleLogin={handleLogin}
                                                handleSignUp={handleSignUp}
                                                handleResetClick={handleResetClick}
                                                handleSignClick={handleSignClick}
                                                handleLoginClick={handleLoginClick}
                                                data={data}
                                                setData={setData}
                                                handleReset={handleReset}
                                                handleClose={handleClose}
                                            />
                                            <Styled.MobileLoginButton onClick={handleClickOpen}>
                                                Login/Signup
                                            </Styled.MobileLoginButton>
                                        </>
                                    )}
                                </Styled.HeaderMobileMenu>
                            </Styled.MobileMenuWrapper>
                            <Styled.MobileLinkUI>
                            </Styled.MobileLinkUI>
                        </Styled.CustomDrawer>
                    </Styled.HeaderMobileContentWrapper></>) : (
                <Styled.HeaderContentWrapper>
                    <Styled.MenuWrapper>
                        <Styled.ImageButton>
                            <Styled.Image src="images/galileosense.webp" alt='Galileosense Logo'></Styled.Image>
                        </Styled.ImageButton>
                        <Styled.HeaderMenu>
                            <PopupButton id="E9DCiao0" style={{ fontSize: 20, backgroundColor: "transparent", border: "0" }} className="my-button">
                                <Styled.LinkUI>
                                    Product
                                </Styled.LinkUI>
                            </PopupButton>
                            <Link to="/Contact" style={{ textDecoration: "none" }}>
                                <Styled.LinkUI>
                                    Contact
                                </Styled.LinkUI>
                            </Link>

                        </Styled.HeaderMenu>
                    </Styled.MenuWrapper>
                    {userData ? (
                        <>
                            <Typography
                                id="profile-button"
                                variant="button"
                                aria-controls={anchorEl ? 'profile-menu' : undefined}
                                aria-expanded={anchorEl ? 'true' : undefined}
                                aria-haspopup="true"
                                style={{ cursor: "pointer" }}
                                onClick={handleProfileClick}>
                                {`Hi ${userData}`}
                            </Typography>
                            <Menu
                                id="profile-menu"
                                anchorEl={anchorEl}
                                open={anchorEl ? true : false}
                                onClose={handleProfileClose}
                                MenuListProps={{
                                    'aria-labelledby': 'profile-button',
                                }}
                            >
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <LoginComponent
                                open={open}
                                setOpen={setOpen}
                                responseGoogleSuccess={responseGoogleSuccess}
                                responseGoogleFailure={responseGoogleFailure}
                                handleLogin={handleLogin}
                                handleSignUp={handleSignUp}
                                handleResetClick={handleResetClick}
                                handleSignClick={handleSignClick}
                                handleLoginClick={handleLoginClick}
                                data={data}
                                setData={setData}
                                handleReset={handleReset}
                                handleClose={handleClose}
                            />
                            <Styled.ButtonWrapper onClick={handleClickOpen}>
                                Login/Signup
                            </Styled.ButtonWrapper>
                        </>
                    )}
                </Styled.HeaderContentWrapper>)}
        </>
    )
}

export default HeaderComponent