import * as yup from "yup";

export const SignupSchema = yup.object().shape({
    firstName: yup
        .string()
        .required("Please enter first name")
        .min(2, "Please enter valid first name")
        .max(20, "Please enter valid first name")
        .matches(/^[^\s]+(\s.*)?$/, "No Blank Spaces"),
    lastName: yup
        .string()
        .required("Please enter last name")
        .min(2, "Please enter valid last name")
        .max(20, "Please enter valid last name")
        .matches(/^[^\s]+(\s.*)?$/, "No Blank Spaces"),
    email: yup
        .string()
        .required("Please enter email")
        .matches(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/, "Please enter a valid Email"),
    password: yup
        .string()
        .min(6, "Password is too, short.")
        .max(20, "Password is too long.")
        .required("This field is required.")
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
            "Must Contain 8 Characters, 2 Number and 2 special case Character and no blank space allowed"
        ),
    confirmPassword: yup
        .string()
        .required("This field is required.")
        .oneOf([yup.ref("password")], "Passwords does not match"),
});
export const LoginSchema = yup.object().shape({
    email: yup.string().required("Please enter Email").matches(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/, "Please enter a valid Email"),
    password: yup
        .string()
        .min(6, "Password is too, short.")
        .max(20, "Password is too long.")
        .required("This field is required.")
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
            "Must Contain 8 Characters, 2 Number and 2 special case Character and no blank space allowed"
        ),
});
export const ResetSchema = yup.object().shape({
    email: yup.string().email().required("Please enter email")
});
export const DemoFormSchema = yup.object().shape({
    firstName: yup
        .string()
        .required("Please enter first name")
        .min(2, "Please enter valid first name")
        .max(20, "Please enter valid first name")
        .matches(/^[^\s]+(\s.*)?$/, "No Blank Spaces"),
    lastName: yup
        .string()
        .required("Please enter last name")
        .min(2, "Please enter valid last name")
        .max(20, "Please enter valid last name")
        .matches(/^[^\s]+(\s.*)?$/, "No Blank Spaces"),
    companyName: yup
        .string()
        .required("Please enter last name")
        .min(2, "Please enter valid last name")
        .max(20, "Please enter valid last name")
        .matches(/^[^\s]+(\s.*)?$/, "No Blank Spaces"),
    email: yup
        .string()
        .required("Please enter email")
        .matches(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/, "Please enter a valid Email"),
    phoneNumber: yup
        .string()
        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid')
        .min(10, "to short")
        .max(10, "to long")
    ,
    useCase: yup
        .string()
        .required("Please enter Use Case Description")
        .min(2, "Please enter valid Use Case Description")
        .max(300, "You can write upto 300 words")
        .matches(/^[^\s]+(\s.*)?$/, "No Blank Spaces"),
});


export default {};
