import { styled } from '@mui/system';
import { Box, Button, InputBase, Drawer } from '@mui/material';

const ContentWrapper = styled(Box)({
    padding: '15px',
    borderRadius: 0,
    background: '#e5e5e5',
    width: 'Calc(100% - 300px)',
    "@media (max-width: 991px)": {
        width: '100%',
        overflow: 'hidden'
    }
});
const MenuWrapper = styled(Box)({
    width: '100%',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});
const HeaderContentWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: '20px',
    borderRadius: 0,
    background: '#205878',
    color: '#fff',
    "@media (max-width: 991px)": {
        position: 'sticky',
        top: '0',
        left: '0',
        right: '0',
        zIndex: '999',
    }
});
const Image = styled('img')({
    width: '100%',
    height: '100%'
});

const ImageButton = styled(Button)({
    width: "100px!important",
    height: "100px!important",
    padding: "0"
});

const Search = styled(Box)({
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: 'white',
    borderBottom: '1px solid #205878',
    borderRadius: '0',
    '&:hover': {
        backgroundColor: '#fff',
    },
    marginLeft: 0,
    width: '100%',
});
const LinkUI = styled('div')({
    textDecoration: 'none',
    fontSize: '24px',
    fontWeight: '600',
    color: 'rgb(255, 255, 255)',
    "&:hover": {
        color: 'rgb(0, 158, 176)',
    }
});
const SearchIconWrapper = styled(Box)({
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    right: '15px',
});

const StyledInputBase = styled(InputBase)({
    color: 'inherit',
    '& .MuiInputBase-input': {
        width: '100%',
        padding: '7px 40px 7px 15px',
    },
});
const HeaderMenu = styled(Box)({
    a: {
        marginLeft: '30px',
        padding: '5px 24px',
        textDecoration: 'none',
        fontSize: '14px',
        display: 'inline-block',
    },
    "@media (max-width: 991px)": {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginTop: '20px',
        a: {
            fontSize: '16px',
            color: '#fff',
            background: 'transparent',
            marginLeft: '25px',
        }
    }
});
const ButtonWrapper = styled(Button)({
    padding: '0 20px',
    fontWeight: 700,
    color: '#fff',
    margin: "22px",
    marginRight: '25px',
    marginLeft: '30px',
    letterSpacing: '2px',
    whiteSpace: 'nowrap',
    fontSize: '20px',
    textTransform: 'none',
    "&:hover": {
        color: 'rgb(0, 158, 176)',
    },
    "@media (max-width: 991px)": {
        textAlign: 'inherit',
        height: 'max-content',
        overflowY: 'inherit',
    }
});
const MobileLoginButton = styled(Button)({
    padding: '17px 2rem',
    fontWeight: 700,
    color: '#fff',
    margin: "22px",
    marginRight: '25px',
    marginLeft: '30px',
    letterSpacing: '2px',
    fontSize: '17px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    "&:hover": {
        backgroundColor: 'rgb(0, 158, 176)'
    },
    "@media (max-width: 991px)": {
        textAlign: 'inherit',
        height: 'max-content',
        overflowY: 'inherit',
        padding: '0'
    }
});
const CustomDrawer = styled(Drawer)({
    "& .MuiPaper-root": {
        width: "60%",
        backgroundColor: '#205878',
    },
    "& .logoSection": {
        padding: "15px",
    },
    "& .navLink": {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        textDecoration: 'none',
        color: '#828380'
    },
    "& .drawerHeader": {
        "& button": {
            display: 'none'
        }
    },
    "& .outerBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .profileWrapper": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: "15px",
        "& .userDrawerImage": {
            width: "50px",
            height: "50px",
            marginRight: "15px",
            cursor: "pointer",
            objectFit: "contain"
        }
    }
});

const MobileHeaderWrapper = styled(Box)({
    width: '100px',
    height: '95px',
    margin: '-9px',
    "@media (max-width: 767px)": {
        paddingLeft: "30px"
    },
    "@media (max-width: 575px)": {
        paddingLeft: "0"
    }
});

const MenuIcon = styled(Button)({
    color: '#fff',
});

const MenuItemsWrapper = styled(Box)({
    "@media (max-width: 991px)": {
        display: 'flex',
    }
});
const HeaderMobileContentWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 0,
    background: '#205878',
    color: '#fff',
    "@media (max-width: 991px)": {
        position: 'sticky',
        top: '0',
        left: '0',
        right: '0',
        zIndex: '999',
    }
});
const MobileProductLinkUI = styled('div')({
    textDecoration: 'none',
    fontSize: '24px',
    fontWeight: '600',
    color: 'rgb(255, 255, 255)',
    "&:hover": {
        color: 'rgb(0, 158, 176)',
        backgroundColor: 'none'
    },
    marginLeft: '25px',
    marginTop: '20px',
    "@media (max-width: 320px)": {
        marginLeft: '10px'
    },
    "@media (max-width: 991px)": {
        fontSize: '18px'
    }
})
const MobileLinkUI = styled('div')({
    textDecoration: 'none',
    fontSize: '24px',
    fontWeight: '600',
    color: 'rgb(255, 255, 255)',
    "&:hover": {
        color: 'rgb(0, 158, 176)',
        backgroundColor: 'none'
    },
    marginLeft: '25px',
    marginTop: '20px',
    "@media (max-width: 320px)": {
        marginLeft: '10px'
    },
    "@media (max-width: 991px)": {
        fontSize: '18px'
    }
});
const HeaderMobileMenu = styled(Box)({
    position: 'relative',
    a: {
        color: '#000a3f',
        background: '#fff',
        marginLeft: '50px',
        textDecoration: 'none',
        fontSize: '14px',
        display: 'inline-block',
        "&:hover": {
            color: '#fff',
        }
    },
    "@media (max-width: 991px)": {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginTop: '20px',
        a: {
            fontSize: '16px',
            color: '#fff',
            background: 'transparent',
            marginLeft: '25px',
        }
    }
});
const MobileMenuWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});


export default {
    ContentWrapper,
    Search,
    HeaderContentWrapper,
    SearchIconWrapper,
    StyledInputBase,
    MenuWrapper,
    HeaderMenu,
    Image,
    LinkUI,
    ButtonWrapper,
    ImageButton,
    CustomDrawer,
    MobileHeaderWrapper,
    MenuIcon,
    MenuItemsWrapper,
    HeaderMobileContentWrapper,
    MobileLinkUI,
    HeaderMobileMenu,
    MobileMenuWrapper,
    MobileLoginButton,
    MobileProductLinkUI
};

