import { styled } from '@mui/system';
import { Box, Button, Grid, Typography } from '@mui/material';

const ContentWrapper = styled(Box)({
    marginTop: "13vh",
    marginBottom: '12vh',
    "@media (max-width: 767px)": {
        width: '100%',
        height: '100%',
        order: '2',
        paddingTop: '0px'
    }
});
const CustomGrid = styled(Grid)({
    paddingLeft: "100px!important",
    margin: "auto",
    "@media (max-width: 767px)": {
        order: '2',
        paddingTop: '50px !important',
        paddingLeft: '60px !important',
        paddingBottom: '20px !important'
    }
});

const GetStartedGrid = styled(Grid)({
    padding: "90px 60px",
    "@media (max-width: 575px)": {
        padding: "60px 40px",
        textAlign: "center"
    }
});

const BoxGalileoAPI = styled(Box)({
    backgroundColor: "#fafafa",
    padding: "20px",
    marginRight: "45px",
    "@media (max-width: 767px)": {
        marginRight: "0px",
        paddingLeft: "50px",
        padding: "20px 45px",
    },
    "@media (max-width: 575px)": {
        padding: "20px 45px",
    },

    "@media (max-width: 991px)": {
        padding: "20px 45px",
    },
    "& sup": {
        backgroundColor: "#8f94a0",
        borderRadius: "10px",
        fontSize: "18px",
        padding: "3px",
        color: "#fff",

    },
})
const GalileosenseAPITypography = styled(Typography)({
    "@media (max-width: 575px)": {
        fontSize: "28px",
    },
})
const Image = styled('img')({
    width: '97%',
    height: '100%',
    borderRadius: '00px',
    backgroundRepeat: "no-repeat",
    objectFit: "contain",
    paddingLeft: "16px",
    paddingRight: "15px",
    "@media (max-width: 767px)": {
        width: '90%',
        paddingLeft: "51px"
    },
    "@media (max-width: 575px)": {
        paddingLeft: "10px",
    }
});
const IgnitionImage = styled('img')({
    width: '97%',
    height: '100%',
    borderRadius: '00px',
    backgroundRepeat: "no-repeat",
    objectFit: "contain",
    paddingLeft: "16px",
    paddingRight: "15px",
    "@media (max-width: 767px)": {
        width: '90%',
        paddingLeft: "51px"
    },
    "@media (max-width: 575px)": {
        paddingLeft: "10px",
        order: "2"
    }
});
const ParagraphTypography = styled(Typography)({
    fontSize: "20px"
});
const ParagraphBox = styled(Box)({
    padding: "0px 282px 30px 56px",
    "@media (max-width: 768px)": {
        padding: "20px 60px"
    },
    "@media (max-width: 320px)": {
        width: '250px !important',
        padding: "20px 30px"
    },
    "@media (max-width: 375px)": {
        width: '250px !important',
        padding: "20px 35px"
    }
});
const GalileosenseHeading = styled(Box)({
    color: "#01043E",
    textShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    fontSize: "58px",
    fontWeight: "500",
    "@media (max-width: 575px)": {
        fontSize: "40px"
    }
})

const PlansButton = styled(Box)({
    '& Button': {
        backgroundColor: "#93c47d",
        color: "#fff",
        marginBottom: "50px",
        maxWidth: "100%",
        minHeight: "110px",
        width: "100%",
        textTransform: "none",
        "@media (max-width: 375px)": {
            width: '250px !important',
            paddingLeft: "0px"
        }
    }
})
const CustomTypograph = styled(Typography)({
    color: "black",
    fontSize: "20px",
    paddingLeft: "50px",
    paddingRight: "35px",
    textAlign: "left",
    maxWidth: "664px",
    '& span': {
        fontWeight: "700",
        color: 'black',
    },
    "@media (max-width: 575px)": {
        paddingLeft: "10px"
    }
});

const CustomTypographRight = styled(Typography)({
    color: "black",
    fontSize: "20px",
    paddingLeft: "20px",
    paddingRight: "35px",
    textAlign: "left",
    '& span': {
        fontWeight: "700",
        color: 'black',
    },
    "@media (max-width: 767px)": {
        paddingLeft: '47px'
    },
    "@media (max-width: 575px)": {
        paddingLeft: "10px",
        order: '1'
    }

});


const CustomBox = styled(Box)({
    margin: '38px 0',
    padding: '0 0 75px 0',
    '& .change-order-first': {
        "@media (max-width: 575px)": {
            order: 2,
        },
        "@media (max-width: 991px)": {
            order: 2,
        }
    },
    '& .change-order-second': {
        "@media (max-width: 575px)": {
            order: 1,
        },
        "@media (max-width: 991px)": {
            order: 1,
        }
    }
});
const CustomVideoBox = styled(Box)({
    textAlign: "center",
    '& Typography': {
        textAlign: "left",
    }
})
const ProductWrapper = styled(Box)({
    height: "850px",
    width: "100%",
    marginTop: "30px",
    backgroundColor: "rgba(250, 250, 250, 0.9)"
});
const TextWrapper = styled(Box)({
    display: "flex",
    padding: "35px",
    flexDirection: "row-reverse",
    height: "320px"
});
const ParagraphWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    flex: "auto",
})

const ImageWrapper = styled('img')({
    left: "100%",
    width: '100%',
    height: '87%',
    "@media (max-width: 767px)": {
        width: '100%',
        height: '100%',
        order: '1',
        paddingTop: '0px'
    },
});
const ButtonWrapper = styled(Button)({
    padding: '10px 3.25rem',
    background: '#205877',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginLeft: '4.8rem',
    "&:hover": {
        backgroundColor: 'rgb(0, 158, 176)'
    },
    "@media (max-width: 1024px)": {
        display: 'block',
        marginLeft: '0px',
    },
    "@media (max-width: 425px)": {
        marginTop: '1rem',
        marginLeft: '0px',
    },
});
const ButtonWrapper1 = styled(Button)({
    padding: '10px 3.25rem',
    background: '#205877',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginTop: '3.8rem',
    "&:hover": {
        backgroundColor: 'rgb(0, 158, 176)'
    },
});
const ButtonNew = styled(Button)({
    borderRadius: 5,
    padding: '22px',
    background: 'rgb(3, 180, 198)',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    "&:hover": {
        backgroundColor: 'rgb(0, 158, 176)'
    },
    "@media (max-width: 991px)": {
        textAlign: 'inherit',
        height: 'max-content',
        overflowY: 'inherit',
    }
});

const CustomGridImage = styled(Grid)({
    "@media (max-width: 767px)": {
        paddingTop: '0 !important'
    }
});

const CustomGridButton = styled(Grid)({
    "@media (max-width: 767px)": {
        paddingTop: '70px !important',
        marginBottom: '20px !important',
        justifyContent: 'center',
        textAlign: 'center',
    }
});

export default {
    ContentWrapper, GalileosenseAPITypography, ButtonWrapper1, ParagraphTypography, GalileosenseHeading, IgnitionImage, ParagraphBox, BoxGalileoAPI, GetStartedGrid, TextWrapper, Image, CustomTypographRight, PlansButton, CustomVideoBox, CustomBox, CustomTypograph, ImageWrapper, ParagraphWrapper, ButtonWrapper, ButtonNew, ProductWrapper, CustomGrid, CustomGridImage, CustomGridButton
}