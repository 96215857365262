import React from 'react'
import { Widget } from '@typeform/embed-react'
import { Box } from '@mui/material'

const Contact = () => {
    return (
        <>
            <Box style={{ backgroundColor: "#1E1C44" }}>
                <Widget id="E9DCiao0" style={{ width: '100vw', height: '100vh', overflow: "hidden" }} className="my-form" />
            </Box>
        </>
    )
}

export default Contact