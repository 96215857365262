import { Grid } from '@mui/material'
import React from 'react'
import Styled from "./style";

const FooterComponent = () => {
    return (
        <>
            <Styled.EmptyBox />
            <Styled.ContentWrapper>
                <Grid container direction="column">
                    <Grid item lg={3}>
                        <Styled.CustomTypo variant="h6" component="div" gutterBottom>
                            Galileosense
                        </Styled.CustomTypo>
                    </Grid>
                </Grid>
                <Grid container spacing={10} direction="row">
                    <Grid item direction="column" justifyContent="space-between"
                        alignItems="flex-start">
                        <Grid item lg={3}>
                            <Styled.CustomTypo2 variant="h8" component="div" gutterBottom>
                                Read and Learn
                            </Styled.CustomTypo2>
                        </Grid>
                        <Grid item lg={3} direction="row" style={{ margin: "10px 0px" }}>
                            <Styled.CommonLink href="#">About</Styled.CommonLink>
                        </Grid>
                        <Grid item lg={3} direction="row" style={{ margin: "10px 0px" }}>
                            <Styled.CommonLink href="#">Products</Styled.CommonLink>
                        </Grid>
                        <Grid item lg={3} direction="row" style={{ margin: "10px 0px" }}>
                            <Styled.CommonLink href="#">Contact</Styled.CommonLink>
                        </Grid>
                    </Grid>
                    <Grid item direction="column" justifyContent="space-between"
                        alignItems="flex-start">
                        <Grid item lg={3} direction="row" >
                            <Styled.CustomTypo2 variant="h8" component="div" gutterBottom>
                                Follow
                            </Styled.CustomTypo2>
                        </Grid>
                        <Grid item lg={3} direction="row" style={{ margin: "10px 0px" }}>
                            <Styled.CommonLink href="https://twitter.com/galileosense" target={"_blank"}>Twitter</Styled.CommonLink>
                        </Grid>
                        <Grid item lg={3} direction="row" style={{ margin: "10px 0px" }} >
                            <Styled.CommonLink href="https://instagram.com/galileosense" target={"_blank"}>Instagram</Styled.CommonLink>
                        </Grid>
                    </Grid>
                </Grid>
            </Styled.ContentWrapper>
            <Styled.FooterEnd>
                <Styled.CommonLinkGalileosense href="#">
                    &copy; Galileosense 2022
                </Styled.CommonLinkGalileosense>
            </Styled.FooterEnd>
        </>
    )
}

export default FooterComponent