import { Button, Grid, Typography, Link } from '@mui/material';
import React, { useState, createContext, lazy, Suspense, useRef, useEffect } from 'react'
import { LayoutContainer } from '../../Container';
import Styled from "./style";
import ReactGA from "react-ga4";
import { DemoForm } from '../../Components'
import CircularProgress from '@mui/material/CircularProgress';
import Integration from '../../Assets/Images/Integration.webp';
import Ignition from '../../Assets/Images/Ignition.webp';
import Intelligence from '../../Assets/Images/Intelligence.webp'
import { useMixpanel } from 'react-mixpanel-browser';


const GalileosenseVideo = lazy(() => import('../../Components/VideoPlayer'))

export const UserContext = createContext();

const LandingPage = () => {
    const mixpanel = useMixpanel();
    const [openForm, setOpenForm] = useState(null)
    const [open, setOpen] = useState(false);

    const eventGoogleAnalytics = () => {
        ReactGA.event({
            category: "Button",
            action: "click",
            label: "Request_Demo"
        });
        mixpanel.track('click', {
            Button: 'Request_Demo',
        });
    }

    const DemoFormOpen = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    return (
        <Suspense fallback={<CircularProgress />}>
            <UserContext.Provider value={{ openForm, setOpenForm }}>
                <LayoutContainer>
                    <section>
                        <Styled.ContentWrapper>
                            <Grid container id="box1" spacing={6} direction="row" >
                                <Styled.CustomGrid item sm={12} md={12} direction="column">
                                    <Styled.GalileosenseHeading variant="h3" component="div" >
                                        Galileosense
                                    </Styled.GalileosenseHeading>
                                    <Typography variant="h6" component="div" gutterBottom style={{ color: "rgb(9, 23, 71)", textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)", fontWeight: "500", fontSize: "27px" }}>
                                        Geospatial Analytics & AI platform to optimize and power your organization
                                        <span>
                                            <Styled.ButtonWrapper variant='contained' onClick={() => { eventGoogleAnalytics(); DemoFormOpen(); }}>
                                                Request Demo
                                            </Styled.ButtonWrapper>
                                            <DemoForm open={open} onClose={onClose} />
                                        </span>
                                    </Typography>
                                </Styled.CustomGrid>
                                {/* <Styled.CustomGridImage item sm={12} md={6} style={{ paddingLeft: "48px", paddingTop: "18px" }}>
                                        <Styled.ImageWrapper src="images/galileosense.webp" alt='Galileosense Logo' />
                                    </Styled.CustomGridImage> */}
                            </Grid>
                        </Styled.ContentWrapper>
                    </section>
                    <section>
                        <Styled.CustomBox>
                            <Grid container id="box2" spacing={2}>
                                <Grid item xs={12} xl={3} lg={4} md={6}>
                                    <Styled.CustomTypograph><span>Integrate</span> with diverse connectors from satellite, geospatial surface analytics, network, location analytics, user behavior, customer insights, change pattern detection, urbanization and infrastructure to natural disaster, business intelligence, open-data and your own data to stitch valuable data on-demand.
                                    </Styled.CustomTypograph>
                                </Grid>
                                <Grid item xs={12} xl={9} lg={8} md={6}>
                                    <Styled.Image src={Integration} alt='Integration Page'></Styled.Image>
                                </Grid>
                            </Grid>
                        </Styled.CustomBox>
                    </section>
                    <section>
                        <Styled.CustomBox>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9} lg={8} className="change-order-first">
                                    <Styled.IgnitionImage src={Ignition} alt='Ignition Page'></Styled.IgnitionImage>
                                </Grid>
                                <Grid item xs={12} md={3} lg={4} className="change-order-second">
                                    <Styled.CustomTypographRight> <span>Ignite</span>, Schedule Jobs, Automate, build workflows real-time or based on your choice to run AIML and large data state of art out of box processing capabilities with custom rules.
                                    </Styled.CustomTypographRight>
                                </Grid>
                            </Grid>
                        </Styled.CustomBox>
                    </section>
                    <section>
                        <Styled.CustomBox>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4} md={3}>
                                    <Styled.CustomTypograph> <span>Intelligence</span> lets you derive insights analytics, AI machine learning model and analytics workflows at scale. Leverage out of box AI capabilities
                                    </Styled.CustomTypograph>
                                </Grid>
                                <Grid item xs={12} lg={8} md={9}>
                                    <Styled.Image src={Intelligence} alt='Intelligence Page'></Styled.Image>
                                </Grid>
                            </Grid>
                        </Styled.CustomBox>
                    </section>
                    <section>
                        <Styled.CustomVideoBox >
                            <Grid container spacing={2}>
                                <Grid item xs={12} ma={6}>
                                    <Styled.CustomTypograph>
                                        <span>Insights</span> Visualize dynamic dashboard for analytics and Business intelligence for optimization, geospatial advanced contextual features, export and leverage apis in applications
                                    </Styled.CustomTypograph>
                                </Grid>
                                <Grid item xs={12}>
                                    <GalileosenseVideo />
                                </Grid>
                            </Grid>
                        </Styled.CustomVideoBox>
                    </section>
                    <section>
                        <Styled.CustomBox style={{ marginBottom: "1px!important" }}>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12} md={6}>
                                    <Styled.CustomTypograph> <span> <Typography variant="h4" component="div"  >API - </Typography> </span>
                                        <Typography style={{ fontWeight: "400", fontSize: "20px", maxWidth: "252px" }}>Leverage APIs into your application and processes</Typography>
                                    </Styled.CustomTypograph>
                                </Grid>
                                <Grid item sm={12} xs={12} md={6} sx={{ paddingBottom: "100px" }}>
                                    <Styled.BoxGalileoAPI>
                                        <Styled.GalileosenseAPITypography variant="h4" component="div" style={{ position: "relative", fontWeight: "700" }} gutterBottom>
                                            Galileosense API  <sup>1.0</sup>
                                            <Typography style={{ color: "#acafb4" }}>
                                                [ Base URl: <Link href="https://api.galileosense.com/swagger-ui.html#/  " target="_blank" style={{ textDecoration: "none", color: "#acafb4" }}>
                                                    api.galileosense.com/
                                                </Link>
                                                ]
                                                <Typography>
                                                    <Link href="https://api.galileosense.com/v2/api-docs?group=public-api" target="_blank">
                                                        https://api.galileosense.com/v2/api-docs?group=public-api
                                                    </Link>
                                                </Typography>
                                            </Typography>
                                        </Styled.GalileosenseAPITypography>
                                        <Typography>
                                            GalileoSense API reference for developers
                                        </Typography>
                                        <Link href="#">
                                            Terms of service
                                        </Link>
                                        <Typography>
                                            Galileosense License
                                        </Typography>
                                    </Styled.BoxGalileoAPI>
                                </Grid>
                            </Grid>
                        </Styled.CustomBox>
                    </section>
                    <Grid container spacing={2} style={{ backgroundColor: "#235977", color: "#fff", }}>
                        <Grid item xs={12}>
                            <Styled.ParagraphBox>
                                <Styled.ParagraphTypography style={{ paddingBottom: "24px" }}>
                                    Who are you?
                                </Styled.ParagraphTypography>
                                <Styled.ParagraphTypography >
                                    Commercial businesses and non-commercial organizations ranging from ecommerce & omni-channel retail, supply chain, disaster monitoring, Change detection, Urbanization and Demography, Climate change earth observation and more! gain actionable insight and improve efficiency of organizations to serve people or be better.
                                </Styled.ParagraphTypography>
                                <Styled.ParagraphTypography >
                                    Connect with us with your case!
                                </Styled.ParagraphTypography>
                            </Styled.ParagraphBox>
                        </Grid>
                    </Grid>
                    <section>
                        <Styled.GetStartedGrid container spacing={2} >
                            <Grid item xs={12} md={4}>
                                <Typography variant="h4" component="div" style={{ fontWeight: "800", fontSize: "20px" }}>
                                    Pricing & Plans
                                </Typography>
                                <Typography style={{ padding: "50px 0 0", fontSize: "20px" }}>
                                    We are a SaaS business with focus on providing APIs and platform for SMBs starting with on demand pricing capabilities and Enterprise customers with subscription and support model.
                                </Typography>
                                <Styled.ButtonWrapper1 variant='contained' onClick={() => { eventGoogleAnalytics(); DemoFormOpen(); }}>
                                    Get Started
                                </Styled.ButtonWrapper1>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Styled.PlansButton>
                                    <Button style={{
                                        backgroundColor: "#93c47d", color: "#424242",
                                    }} >
                                        <Typography variant="h6" style={{ fontWeight: "800" }}>
                                            Enterprise<br />Managed multi-cloud platform with support, apis and more plans
                                        </Typography>
                                    </Button>
                                    <Button style={{ backgroundColor: "#c0791b", color: "#424242" }}>
                                        <Typography variant="h6" style={{ fontWeight: "800" }} >
                                            Mid-size companies<br />(on-demand, subscription and more capabilities) 20$/user
                                        </Typography>
                                    </Button>
                                    <Button style={{ backgroundColor: "#4a86e8", color: "#424242" }}>
                                        <Typography variant="h6" style={{ fontWeight: "800" }} >
                                            Small teams, Individuals<br />freemium and 15$/user
                                        </Typography>
                                    </Button>
                                </Styled.PlansButton>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                        </Styled.GetStartedGrid>
                    </section>
                </LayoutContainer>
            </UserContext.Provider >
        </Suspense>
    )
}

export default LandingPage