import React, { useEffect } from 'react'
import Styled from "./style";
import { DemoFormSchema } from '../../Utility/Validation';
import { Grid, DialogTitle, List, Dialog, FormHelperText } from '@mui/material';
import { Formik, Form } from "formik";
import { db } from "../../Firebase/firebaseConfig"

const DemoForm = (props) => {
    const DemoTestUserRef = db.collection('Request-Demo-user-collection')
    const { open, onClose } = props;
    const handleSubmit = async (data) => {
        await DemoTestUserRef.add({
            firstName: data.firstName,
            lastName: data.lastName,
            companyName: data.companyName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            useCase: data.useCase,
            displayName: (data.firstName + " " + data.lastName)
        }).then(() => {
            alert("Successfully Submitted")
            onClose();
        }).catch((error) => {
            alert("Failed to create record " + error);
        });
    }

    return (
        <>
            <Dialog onClose={onClose} open={open} style={{ width: "100%", margin: "0 !important" }} className="request-demo-form">
                <Styled.ContentWrapper>
                    <Styled.FormWrapper>
                        <Formik
                            initialValues={{
                                firstName: "",
                                lastName: "",
                                companyName: "",
                                email: "",
                                phoneNumber: "",
                                useCase: ""
                            }}
                            validationSchema={DemoFormSchema}
                            onSubmit={(values) => {
                                handleSubmit(values);
                            }}
                        >
                            {({ errors, handleChange, touched, values, handleBlur }) => (
                                <Form>
                                    <DialogTitle style={{ color: "#fff" }}>Request Demo</DialogTitle>
                                    <List sx={{ pt: 0 }}>
                                        <Grid container direction="column" spacing={3} justifyContent="center" alignItems="center"  >
                                            <Grid item lg={12}>
                                                <Styled.CustomTextField
                                                    fullWidth
                                                    id="outlined-basic"
                                                    name="firstName"
                                                    placeholder="First Name"
                                                    value={values.firstName}
                                                    onChange={(e) => handleChange(e)}
                                                    onBlur={handleBlur}
                                                    error={errors.firstName}
                                                />
                                                {errors.firstName && touched.firstName && <FormHelperText error>{errors.firstName}</FormHelperText>}
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Styled.CustomTextField
                                                    fullWidth
                                                    id="outlined-basic"
                                                    name="lastName"
                                                    placeholder="Last Name"
                                                    value={values.lastName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.lastName} />
                                                {errors.lastName && touched.lastName && < FormHelperText error>{errors.lastName}</FormHelperText>}
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Styled.CustomTextField
                                                    fullWidth
                                                    id="outlined-basic"
                                                    name="companyName"
                                                    placeholder="Company Name"
                                                    value={values.companyName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.companyName} />
                                                {errors.companyName && touched.companyName && < FormHelperText error>{errors.companyName}</FormHelperText>}
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Styled.CustomTextField
                                                    fullWidth
                                                    id="outlined-basic"
                                                    name="phoneNumber"
                                                    placeholder="Phone Number"
                                                    value={values.phoneNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.phoneNumber} />
                                                {errors.phoneNumber && touched.phoneNumber && < FormHelperText error>{errors.phoneNumber}</FormHelperText>}
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Styled.CustomTextField
                                                    fullWidth
                                                    id="outlined-basic"
                                                    name="useCase"
                                                    placeholder="Usecase"
                                                    value={values.useCase}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.useCase} />
                                                {errors.useCase && touched.useCase && < FormHelperText error>{errors.useCase}</FormHelperText>}
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Styled.CustomTextField fullWidth id="outlined-basic" name="email" placeholder="Email" value={values.email} onChange={handleChange} onBlur={handleBlur} error={errors.email} />
                                                {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Styled.ButtonColor variant="contained" type="submit">SUBMIT</Styled.ButtonColor>
                                            </Grid>
                                        </Grid>
                                    </List>
                                </Form>)
                            }
                        </Formik>
                    </Styled.FormWrapper>
                </Styled.ContentWrapper>
            </Dialog >
        </>
    )
}

export default DemoForm