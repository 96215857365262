import { styled } from '@mui/system';
import { Box, Link, Typography } from '@mui/material';

const ContentWrapper = styled(Box)({
    borderRadius: 0,
    color: 'white',
    padding: '50px',
    paddingRight: '0px',
    background: 'rgb(4, 11, 36)',
    width: '100%',
    paddingLeft: '0'

});
const FooterEnd = styled(Typography)({
    borderRadius: 0,
    color: 'white',
    padding: '10px',
    paddingRight: '0px',
    background: 'rgb(4, 10, 31)',
    width: '100%',
    paddingLeft: '0'

});

const CustomTypo = styled(Typography)({
    fontSize: '24px',
    fontWeight: '600',
    color: 'rgb(255, 255, 255)',
    lineHeight: '1.5',
    marginTop: '42px',
    padding: '0 50px'
})
const CustomTypo2 = styled(Typography)({
    marginTop: '27px',
    marginBottom: '27px',
    fontSize: '18px',
    fontWeight: '600',
    color: 'rgb(255, 255, 255)',
    lineHeight: '1.5',
    padding: '0 50px'
})
const CommonLink = styled(Link)({
    fontSize: '24px',
    fontWeight: '600',
    color: 'rgb(255, 255, 255)',
    lineHeight: '1',
    borderRadius: '0px',
    fontStyle: 'normal',
    margin: '27px',
    textDecoration: 'none',
    padding: '0 28px',
    "&:hover": {
        color: 'rgb(0, 158, 176)'
    }
})
const CommonLinkGalileosense = styled(Link)({
    fontWeight: '600',
    color: 'rgb(255, 255, 255)',
    lineHeight: '1',
    borderRadius: '0px',
    fontStyle: 'normal',
    textDecoration: 'none',
    paddingLeft: '44px',
    width: '120px !important',
    display: 'block',
    padding: '0 50px',
    "&:hover": {
        color: 'rgb(0, 158, 176)'
    }
});
const EmptyBox = styled(Box)({
    height: '10vh',
})

export default {
    ContentWrapper, FooterEnd, EmptyBox, CustomTypo, CustomTypo2, CommonLink, CommonLinkGalileosense

}