import { Box, styled } from '@mui/system';

export const MainContentWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '100vw',
    },
    [theme.breakpoints.down('lg')]: {
        marginRight: 0,
        marginLeft: 0,
        width: '100%',
    },
}));

